<template>
  <div class="gg-container" style="padding: 10px 20px">
    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="代理对比" name="first">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>月份：</label>
            <el-date-picker
              v-model="month"
              type="month"
              size="small"
              clearable
              placeholder="月份"
              @change="getListSecond('restPage')"
            >
            </el-date-picker>
          </div>

          <!-- <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationContrast()"
          > -->
            <!-- <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          <!-- </el-button> -->
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataSecond"
          style="width: 100%"
        >
          <el-table-column
            fixed
            label="代理姓名"
            prop="proxy_name"
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="pic_diag_count"
            label="图文问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pic_diag_amount"
            label="图文问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_count"
            label="视频问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_amount"
            label="视频问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="diag_amount"
            label="问诊金额小计"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="diag_count" label="问诊数" align="center">
          </el-table-column>
          <el-table-column label="退诊数" align="center">
            <el-table-column
              prop="refund_diag_no_count"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_count"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="退诊金额" align="center">
            <el-table-column
              prop="refund_diag_no_amount"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_amount"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="emr_count" label="电子病历数" align="center">
            <el-table-column
              prop="ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="pres_count" label="电子处方数" align="center">
            <el-table-column
              prop="wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
     
          <el-table-column
            prop="doctor_sign_count"
            label="医师签约数"
            align="center"
          >
          </el-table-column>

        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getListSecond"
        />
      </el-tab-pane>

      <el-tab-pane label="代理详情" name="second">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>年份：</label>
            <el-date-picker
              v-model="searchParamsFirst.year"
              type="year"
              size="small"
              clearable
              placeholder="选择年"
              value-format="yyyy"
              @change="getList('restPage')"
            >
            </el-date-picker>
          </div>

          <div class="search-container-fn-input">
            <label>代理：</label>
            <el-select
              v-model="searchParamsFirst.proxy_id"
              placeholder="请选择"
              size="small"
              clearable
              @change="getList('restPage')"
            >
              <template slot="prefix">
                <i
                  class="el-icon-search"
                  style="width: 25px; line-height: 32px"
                ></i>
              </template>
              <el-option
                v-for="item in organizationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationDetail()"
          >
            <!-- <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          </el-button>
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataFirst"
          style="width: 100%"
        >
          <el-table-column label="月份" prop="month" align="center" width="80">
          </el-table-column>
          <el-table-column
            prop="pic_diag_count"
            label="图文问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pic_diag_amount"
            label="图文问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_count"
            label="视频问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_amount"
            label="视频问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="diag_amount"
            label="问诊金额小计"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="diag_count" label="问诊数" align="center">
          </el-table-column>
          <el-table-column label="退诊数" align="center">
            <el-table-column
              prop="refund_diag_no_count"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_count"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="退诊金额" align="center">
            <el-table-column
              prop="refund_diag_no_amount"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_amount"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="emr_count" label="电子病历数" align="center">
            <el-table-column
              prop="ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="pres_count" label="电子处方数" align="center">
            <el-table-column
              prop="wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
         
          <el-table-column
            prop="doctor_sign_count"
            label="医师签约数"
            align="center"
          >
          </el-table-column>
         
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getReportDetail,
  getProxyReportCompareList,
  getProxyList,
} from "@/api/finance";
import { parseTime } from "@/utils";

export default {
  name: "proxyReport",
  components: {
    Pagination,
  },
  created() {
    this._getAdminCommonMerchants();

    this.getListSecond();
    
  },
  data() {
    return {
      activeName: "first",
      searchParamsFirst: {
        year: this.$moment().format("YYYY"),
        proxy_id: 0,
      },
      month: this.$moment().format("YYYY-MM"),
      searchParamsSecond: {
        month: "",
      },
      organizationList: [],
      listLoading: false,
      tableDataFirst: [],
      tableDataSecond: [],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
    };
  },
  methods: {
    handleClickTabs(tab, event) {
      console.log(tab, event);
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = { ...this.searchParamsFirst };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getReportDetail(params)
        .then((response) => {
          let data = response;
    
          if (data.code == 200) {
            this.tableDataFirst = data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    getListSecond(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      // this.listLoading = true
      let date = new Date(this.month);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      this.searchParamsSecond.month = y + "" + m;
      let params = { ...this.searchParamsSecond };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getProxyReportCompareList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableDataSecond = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    _getAdminCommonMerchants() {
      getProxyList().then((response) => {
        if (response.code == 200) {
          this.organizationList = response.data;
        
          let defaultProxyId = this.organizationList.length > 0 ? this.organizationList[0].id : 0
          this.searchParamsFirst.proxy_id = defaultProxyId
              this.getList();
        }
      });
    },
    handleExportOrganizationDetail() {},
    handleExportOrganizationContrast() {},
    handleExportPayOrder() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "机构名称",
          "医生名称",
          "银行卡号",
          "姓名",
          "提现金额",
          "排序",
          "提现状态",
          "提现申请时间",
          "结算编号",
        ];
        const filterVal = [
          "address",
          "date",
          "name",
          "name",
          "name",
          "name",
          "name",
          "name",
          "name",
        ];
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "测试导出",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
  },
};
</script>

<style scoped>
</style>
